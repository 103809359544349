// Session storage keys

/**
 * Used to maintain the state of the active device tab in-between re-renders
 */
export const ACTIVE_DEVICE_TAB = "ADA_ACTIVE_DEVICE_TAB";

/**
 * Used to maintain the state of the client's browser's WebGL2 support
 */
export const WEB_GL_STATUS = "ADA_WEB_GL_STATUS";
